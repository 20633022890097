body {
    background-color: #d6a828;
}

.App {
    padding: 0.5rem;
}

.title {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}

.title h1 {
    margin: 1.5rem;
    font-size: 2rem;
    color:#342210;
}

.dataContainer {
    padding:1.5rem;
    background-color: #ffffff;
}

.footer {
    margin:1rem auto;
}

.footer a {
    color: #342210;
}

.footer a:hover {
    text-decoration: underline;
}

.table-row-light {
    background-color: #ffffff;
}

.table-row-dark {
    background-color: #fbfbfb;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: #d6a828;
    color: #fff;
}

.ant-table-tbody > tr.ant-table-row:hover > td a {
    color: #fff;
}